.faqs {

  &__container {
    @include container();
    max-width: 100%;
  }

  h1 {
    display: none;
  }
  ul {
    padding: 1.5rem;
    box-shadow: 0px 0px 2px rgba(color($greys, charcoal), 0.25);
    border-radius: 2px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &__header {
    font-size: 1.5rem;
    line-height: 150%;
    font-weight: 700;
    margin-bottom: .5rem;
    font {
      color: color($greys, charcoal);
    }
  }
  &__description {
    font-size: 1.3rem;
    line-height: 150%;
    color: color($greys, ash);
    opacity: 0.9;
    font-weight: 400;
    margin-bottom: 2.5rem;
    li:last-of-type & {
      margin-bottom: 0;
    }
  }
}
