/*------------------------------------*\
  #VARIABLES
\*------------------------------------*/

/*------------------------------------*\
  #VARIABLES -> PATHS
\*------------------------------------*/
// Paths
$img-path: '~/assets/images';

$fa-font-path: '/assets/fonts';

/*------------------------------------*\
  #VARIABLES -> TYPOGRAPHY
\*------------------------------------*/

/*
  IMPORTANT: Fonts are loaded via a link in the html.
             This will need to be done for both the actual site and changed in the styleguide template ('styleguide_docs/template/test.hbs')
*/

$font-headline: 'PaddyMischief', sans-serif;
$font-secondary: 'Penumbra Sans';
$font-body: 'Roboto', sans-serif;

$font-default-size: 62.5%;

$font-h1-size: 2.4rem;
$font-h2-size: 1.7rem;
$font-h3-size: 1.3rem;
$font-h4-size: 1.4rem;

$font-body-size: 1.6rem;
$font-body-small-size: 1.2rem;
$font-label-size: 1.2rem;
$font-input-size: 1.6rem;
$font-button-size: 1.6rem;
$font-button-large-size: 1.7rem;
$font-numbers: 4.8rem;
$font-message: 1.8rem;
$font-tooltip: 1.2rem;

$font-weight: normal;
$font-weight-bold: bold;

$line-height-100: 100%;

$font-paragraph-size: $font-body-size;

$max-width: 60rem;

/*------------------------------------*\
  #VARIABLES -> COLOURS
\*------------------------------------*/

// Modal overlay colour
$color-overlay: rgba(#054C46, 0.8)
;
/**
 * The Paddy Power Onside IOS, Android and Web apps will feature a cohesive color palette that reflects the company's guidelines.
 * @section Colours
 * @page Colours
 */

/////////////////  Greys    ///////////////////////

/**
 * Used primarily for text color and backgrounds
 * @section Greys
 * @sectionOf Colours
 * @page Colours
 */

$greys: (
  charcoal : #191919,
  gunpowder: #474752,
  dark-grey: #666666,
  manatee:#73737D,
  wood-ash: #D5D5D8,
  ghost-white: #F2F2F7,
  white: #ffffff,
  quill-grey: #E3E3DE
);

@each $name, $color in $greys {
  .#{$name} {
    background-color: $color;
  }
  .#{$name}-text {
    color: $color;
  }
}

/**
 * @colour {#191919} CHARCOAL|charcoal - Greys
 */

/**
 * @colour {#474752} GUNPOWDER|gunpowder - Greys
 */

/**
 * @colour {#73737D} MANATEE|manatee - Greys
 */

/**
 * @colour {#D5D5D8} WOOD ASH|wood-ash - Greys
 */

/**
 * @colour {#F2F2F7} GHOST WHITE|ghost-white - Greys
 */

/**
 * @colour {#ffffff} WHITE|white - Greys
 */


/////////////////  Brand Colors  ///////////////////////

/**
 * Brand colours.
 * @section Brand colors
 * @sectionOf Colours
 * @page Colours
 */

 $brand-colors: (
  paddy-green: #31953E,
  paddy-dark-green: #004833
);

@each $name, $color in $brand-colors {
  .#{$name} {
    background-color: $color;
  }
  .#{$name}-text {
    color: $color;
  }
}

/**
 * @colour {#31953E} PADDY GREEN|paddy-green - Brand colors
 */

/**
 * @colour {#004833} PADDY DARK GREEN|paddy-dark-green - Brand colors
 */

/////////////////  Accent Colors  ///////////////////////

/**
 * Used primarily for calls to action, highlights and success/error states.
 * @section Accent colors
 * @sectionOf Colours
 * @page Colours
 */

$accent-colors: (
  pigment-green: #009E51,
  dark-green: #006858,
  darker-green: #004D41,
  panache: #D9EAE0,
  stiletto: #BB3838,
  errors: #ff4747,
  yellow: #FFED00
);

@each $name, $color in $accent-colors {
  .#{$name} {
    background-color: $color;
  }
  .#{$name}-text {
    color: $color;
  }
}

/**
 * @colour {#009E51} PIGMENT GREEN|pigment-green - Accent colors
 */

/**
 * @colour {#006858} DARK GREEN|dark-green - Accent colors
 */

/**
 * @colour {#D9EAE0} PANACHE|panache - Accent colors
 */

/**
 * @colour {#BB3838} STILETTO|stiletto - Accent colors
 */


/**
 * @doc ../../../styleguide/docs/css_markdown/colors.md
 *
 * @section Usage Guidelines
 * @sectionOf Colours
 * @page Colours
 */

 // Lengths
$media-xs-width: 376px;
