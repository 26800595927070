/**
 * Input Default
 *
 * @section
 * @page Forms
 *
 * @example
 * <form>
 *   <div class="form__group">
 *     <input class="form__input" type="text" placeholder="Placeholder" value="+ 00 000 000 00"/>
 *     <input class="form__input form__input--focus" type="text" placeholder="Placeholder" value="+ 00 000 000 00"/>
 *     <input class="form__input form__input--disabled" disabled="disabled" type="text" placeholder="Placeholder" value="+ 00 000 000 00"/>
 *   </div>
 * </form>
 *
 */

.form {
  &__group {
    position: relative;
    margin-bottom: 2.5rem;
  }

  &__input {
    @include formInputs;
    width: 100%;

    &--error {
      background-color: rgba(color($accent-colors, errors), 0.1);
    }

    &--large {
      height: 5rem;
    }
  }

  /**
   * Input with icon
   *
   * @section
   * @page Forms
   *
   * @example
   * <form>
   *      <div class="form__group">
   *        <div class="form__input-with-icon">
   *          <i class="fa fa-mobile-alt form__icon"></i>
   *          <input class="form__input" type="text" placeholder="Placeholder" value="+ 00 000 000 00"/>
   *        </div>
   *        <div class="form__input-with-icon">
   *          <i class="fa fa-mobile-alt form__icon"></i>
   *          <input class="form__input form__input--focus" type="text" placeholder="Placeholder" value="+ 00 000 000 00"/>
   *        </div>
   *        <div class="form__input-with-icon">
   *          <i class="fa fa-mobile-alt form__icon"></i>
   *          <input class="form__input form__input--disabled" disabled="disabled" type="text" placeholder="Placeholder" value="+ 00 000 000 00"/>
   *        </div>
   *        <div class="form__input-with-icon">
   *          <i class="fa fa-mobile-alt form__icon"></i>
   *          <input class="form__input" type="text" placeholder="Placeholder" value="+ 00 000 000 00"/>
   *          <i class="far fa-check-circle form__icon"></i>
   *        </div>
   *        <div class="form__input-with-icon">
   *          <i class="fa fa-mobile-alt form__icon"></i>
   *          <input class="form__input" type="text" placeholder="Placeholder" value="+ 00 000 000 00"/>
   *          <i class="fas fa-chevron-right form__icon-button"></i>
   *        </div>
   *      </div>
   *   </form>
   */
  &__input-with-icon {
    position: relative;

    & .form__icon {
      color: color($greys, manatee);
      position: absolute;
      top: 50%;
      margin-top: -0.8rem;

      &:first-child {
        margin-top: -0.8rem;
        left: 1.1rem;
      }

      &:last-child {
        right: 1.1rem;
        color: color($brand-colors, paddy-green);

        &.error {
          color: color($accent-colors, stiletto);
        }
      }
    }

    & .form__icon-button {
      position: absolute;
      font-size: 2rem;
      color: #fff;
      background-color: color($brand-colors, paddy-green);
      top: 1px;
      right: 1px;
      padding: 1.2rem 1.4rem 1.1rem 1.5rem;
      border-radius: 2px;
      cursor: pointer;
    }

    & .form__input {
      padding-left: 3.5rem;
    }

    &--right {
      & .form__icon:last-child {
        position: absolute;
        top: 50%;
        margin-top: -0.8rem;
        font-size: 1.8rem;
        right: 1.1rem;
      }

      & .form__input {
        padding-right: 3.5rem;
      }
    }
  }

  /**
   * Input Error
   *
   * @section
   * @page Forms
   *
   * @example
   * <form>
   *      <div class="form__group">
   *        <label class="form__label">Your Phone Number</label>
   *        <input class="form__input" type="text" placeholder="Placeholder" value="+ 00 000 000 00"/>
   *        <div class="tooltip tooltip--top tooltip--error tooltip--show">
   *            Input is invalid
   *        </div>
   *      </div>
   *   </form>
   */

  /**
   * Toggle
   *
   * @section
   * @page Forms
   *
   * @example
   * <form>
   *   <input class="form__toggle" type="checkbox" checked="checked" />
   *   <input class="form__toggle" type="checkbox" />
   *   <input class="form__toggle" type="checkbox" disabled="disabled" />
   * </form>
   */

  &__toggle {
    appearance: none;
    width: 4.2rem;
    height: 2.2rem;
    display: inline-block;
    position: relative;
    border-radius: 50px;
    overflow: hidden;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: color($greys, wood-ash);
    transition: background-color ease 0.3s;

    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 2;
      width: 1.8rem;
      height: 1.8rem;
      background: #fff;
      left: 2px;
      top: 2px;
      border-radius: 50%;
      text-transform: uppercase;
      font-weight: bold;
      text-indent: -2.2rem;
      word-spacing: 3.7rem;
      color: #fff;
      text-shadow: -1px -1px rgba(0,0,0,0.15);
      white-space: nowrap;
      box-shadow: 0 1px 2px rgba(0,0,0,0.2);
      transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
    }

    &:checked {
      background-color: color($brand-colors, paddy-green);
    }

    &:checked:before {
      left: 2.2rem;
    }

    &[disabled="disabled"] {
      opacity:0.4;
    }
  }

  /**
   * Textarea
   *
   * @section
   * @page Forms
   *
   * @example
   * <form>
   *   <textarea class="form__textarea" rows="4"></textarea>
   * </form>
   */
  &__textarea {
    @include formInputs;
    line-height: 1.2;
    resize: none;
    padding: 1rem 1.5rem 1rem 0;
  }

  /**
   * Select item
   *
   * @section
   * @page Forms
   *
   * @example
   * <div class="form__select">
   *   <select class="form__select-list">
   *     <option>Option 1</option>
   *     <option selected>Option 2</option>
   *     <option>Option 3</option>
   *     <option>Option 4</option>
   *   </select>
   * </div>
   */

  &__select {
    max-width: 25rem;
    position: relative;

    &:after {
      position: absolute;
      content: "";
      pointer-events: none;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid color($greys, manatee);
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    &-list {
      width: 25rem;
      height: 4rem;
      padding: 0.5rem 0 0.5rem 1.5rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: color($greys, manatee);
      border: 1px solid color($greys, manatee);
      border-radius: 2px;
      appearance: none;
      background: none 96% / 15% no-repeat color($greys, ghost-white);

      ::-ms-expand {
        display: none;
        /* remove default arrow in IE 10 and 11 */
      }
    }
  }

  &__label {

    &--white {
      color: #fff;
    }

    &--error {
      color: color($accent-colors, errors);
    }
  }

  &__errors {
    @extend .label;

    color: color($accent-colors, errors);
    margin-top: 0.75rem;
    text-align: left;
  }
}

// Hide eye icon from password field IE

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}
