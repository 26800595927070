/*------------------------------------*\
  #TOOLTIPS
\*------------------------------------*/

/**
  * @doc ../../../styleguide/docs/css_markdown/tooltips.md
  *
  * @section
  * @page Tooltips
**/

.tooltip {
  border-radius: 2px;
  font-size: $font-tooltip;
  background-color: color($greys, ash);
  padding: 0.7rem;
  color: #fff;
  visibility: hidden;
  text-align: center;
  position: absolute;
  z-index: 1;
  line-height: 1em;
  white-space: nowrap;

  &::after {
    content: " ";
    position: absolute;
    border-style: solid;
    border-width: 5px;
    border-color: color($greys, ash)
  }

  &--error {
    background-color: color($accent-colors, stiletto);

    &::after {
      border-color: color($accent-colors, stiletto)
    }
  }

  &--show {
    visibility: visible;
  }

  &--top {
    top: -27px;
    right: 0px;

    &::after {
      top: 100%; /* At the bottom of the tooltip */
      left: 80%;
      margin-left: -5px;
      border: {
        right-color: transparent;
        bottom-color: transparent;
        left-color: transparent;
      }
    }
  }

  &--right {
    &::after {
      top: 50%;
      right: 100%; /* To the left of the tooltip */
      margin-top: -5px;
      border: {
        top-color: transparent;
        bottom-color: transparent;
        left-color: transparent;
      }
    }
  }

  &--bottom {
    bottom: 0px;
    right: 0px;

    &::after {
      bottom: 100%;  /* At the top of the tooltip */
      left: 80%;
      margin-left: -5px;
      border: {
        right-color: transparent;
        top-color: transparent;
        left-color: transparent;
      }
    }
  }

  &--left {
    &::after {
      top: 50%;
      left: 100%; /* To the right of the tooltip */
      margin-top: -5px;
      border: {
        right-color: transparent;
        bottom-color: transparent;
        top-color: transparent;
      }
    }
  }
}
