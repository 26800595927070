.loader {
  &__img {
    background: {
      image: url('#{$img-path}/vectors/content-loader.svg');
      repeat: repeat-y;
      size: contain;
    }
    height: calc(100vh - 6rem);
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &__placeholder {
    @for $i from 1 through 5 {
      &:nth-child(#{$i}){
        width: percentage(random(100) / 100);
        background-color: color($greys, wood-ash);
        height: .8rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}
