/*------------------------------------*\
  #CARD
\*------------------------------------*/


/**
  * Container
  *
  * Containers are used to wrap contents together, see <a href="cards.html">cards</a> as an example.
  *
  * @section
  * @page Container
  *
  * @example
  * <section class="container">
      <p class="container__para">Mi egestas lectus eu urna nunc ullam corper ut a egestas quis adipiscing leo a aucto rnunc a sagittis cubilia ut convallis adipiscing.</p>
    </section>
**/
.container {
  @include container();

  &--para {
    color: color($greys, gun-powder);
    font-weight: $font-weight;
    padding: 3rem 2rem;
  }
}
