/*------------------------------------*\
  #FUNCTIONS
\*------------------------------------*/

/*
/// Returns a colour from mapped global colours.
///
/// @access private
///
/// @param {Color-Name [string]} $number — mapped object name
///
/// @param {Color-Variant [string]} $number — colour to retrieve
///
/// @returns {String}
///
///
/// @example scss - Usage
///     color($greys, light-grey-blue)
///     color($greys, ghost-white)
*/

@function color($color-name, $color-variant) {
  // map inception
  @return map-get($color-name, $color-variant);
}
