/*------------------------------------*\
  #MODAL
\*------------------------------------*/

/**
 * @doc ../../../styleguide/docs/css_markdown/modals.md
 *
 * @section
 * @page Modals
 */

 .modal {
  @include flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 100%;
  height: auto;
  box-sizing: border-box;
  position: relative;
  max-width: 100%;

  &__overlay {
    @include overlay;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    z-index: 998;
  }

  &__inner, & .nsm-content {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 999;
    background: #fff;
    text-align: center;
    margin: 0;
    padding: 2.6rem;
  }

  &__content, & .nsm-body {
    @extend .mw;
    font-size: 1rem;
    font-weight: 600;
    padding: 3rem 0;

    & p {
      margin-bottom: 3rem;
    }
  }

  &--center {
    align-items: center;
  }

  &--fullscreen {
    max-width: 100%;

    & .modal__content, & .nsm-body {
      max-width: 100%;
    }
  }

  &--iframe, &--no-padding {
    padding: 0;
    max-width: 100%;
    flex: 1 1 auto;
    align-items: initial;

    & .modal__inner, & .nsm-content {
      border-radius: 0;
      padding: 0;
      min-width: 100%;
      margin: 0;
      background-color: transparent;
    }

    & .modal__content, & .nsm-body {
      height: 100%;
      width: 100%;
      padding: 0;
    }

    & iframe {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
}

