.header {
  &__left {
    & .fa, & .fas, & .far {
      font-size: 2.4rem;
      cursor: pointer;
    }

    & > a, & > a:visited {
      color: color($greys, gunpowder);
    }
  }
}
