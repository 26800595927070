/*------------------------------------*\
  #SLIDER
\*------------------------------------*/

/**
 * Sliders are typically used for actions that require a prolonged interaction (to avoid accidentally triggering it), like cashing out.
 * @section Slider
 * @page Slider
 *
 * @example
 * ../../../styleguide/docs/markup/cash-out/cash-out.html
 */

.dragdealer {
  background-color: #fff;
  position: relative;
  text-align: center;
  border-radius: 2px;
  height: 4rem;

  &__text {
    color: color($brand-colors, paddy-dark-green);
    font-weight: bold;
    line-height: 4.4rem;
  }

  &__handle {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    border-radius: 2px;
    background-color: color($brand-colors, paddy-dark-green);
    color: #fff;
    padding-right: 1rem;
    user-select: none;
    min-width: 4rem;
    overflow: hidden;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 0;

    & > div {
      position: absolute;
      top: 0;
      left: 0;
      background-color: transparent;
      user-select: none;
      width: 100%;
      overflow: hidden;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 0;

      & > span {
        @include font($font-weight-bold, $line-height-100, $font-body-size, $font-body);

        position: absolute;
        text-align: center;
        left: 0;
      }
    }
  }
}
