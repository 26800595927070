@import 'abstracts/variables';

/*------------------------------------*\
  #HELPERS
\*------------------------------------*/

.fl { float:left; }
.fr { float:right; }
.mw {
  max-width: $max-width;
  margin: 0 auto;
}
.ir {
  max-width: 100%;
}
.tr {
  text-align: right;
}

.hr-helper {
  position: relative;
  margin: 5rem 0;

  &__stroke {
    display: block;
    height: .1rem;
    width: 100%;
    background-color: color($greys, ghost-white);
  }

  &__text {
    position: absolute;
    left: 50%;
    top: -1rem;
    transform: translateX(-50%);
    background-color: color($greys, white);
    padding: .1rem 4rem;
  }
}

.nowrap {
  white-space: nowrap;
}

.flex-column {
  @include flex-column;
}

/*------------------------------------*\
  #STATE HOOKS
\*------------------------------------*/

/**
  // Used for elements that may need to be visually different
  // if the current state is-active
  .is-active {}

  // Used for elements where the container can be expanded/hidden
  // Add if container expanded
  .is-expanded {}
  // Add if container collapsed
  .is-collapsed {}

  // If element is visible on the page
  .is-visible {}

  // If page/element is currently loading
  .is-loading {}

  // If element is disabled
  .is-disabled {}

  // If element has loaded
  .has-loaded {}
 */
