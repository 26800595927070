/**
 * This section offers guidelines on how to use typography within the Paddy Power Onside IOS, Android and Web apps in order to reflect the brand guidelines.
 *
 * @section Typography
 * @page Typography
 */

/**
 * @doc ../../../styleguide/docs/css_markdown/font-family.md
 *
 * @section
 * @page Typography
 */

/**
 * @doc ../../../styleguide/docs/css_markdown/typography.md
 *
 * @section
 * @page Typography
 */

html {
  @include font($font-weight, $line-height-100, $font-default-size);
}

body,
.body {
  color: color($greys, charcoal);
  font-size: $font-body-size;
}

.body-bold {
  @include font($font-weight-bold, $line-height-100);
}

h1,
.h1,
.title {
  @include font($font-weight-bold, $line-height-100, $font-h1-size, $font-headline);
}

h2,
.h2,
.subtitle {
  @include font($font-weight, $line-height-100, $font-h2-size);
}

h3,
.h3 {
  @include font($font-weight, $line-height-100, $font-h3-size);
}

h4,
.h4 {
  @include font($font-weight, $line-height-100, $font-h4-size);
}

label,
.label {
  @include font($font-weight-bold, $line-height-100, $font-label-size);
  color: color($greys, manatee);
  font-weight: bold;
}

a,
.link {
  line-height: inherit;
  transition: margin-left 0.2s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.2rem;
  color: color($accent-colors, pigment-green);
  font-weight: bold;

  &:hover,
  &:visited {
    text-decoration: none;
  }

  /*&:visited {
    color: color($greys, gunpowder);
  }*/
}

p,
.para {
  @include font($font-weight, $line-height-100);
  // text-align: left;
  color: color($greys, gunpowder);
}

.small-text {
  @include font($font-weight, $line-height-100, $font-body-small-size);
}

.helper-text {
  display: block;
  opacity: 0.8;
  font-size: 1.4rem;
  margin-top: 1rem;
  line-height: 120%;
}

.message {
  @include font($font-weight-bold, $line-height-100, $font-message);
}

.message-header {
  @include font($font-weight-bold, $line-height-100, $font-body-small-size);
  text-transform: uppercase;
}

.number {
  @include font($font-weight-bold, $line-height-100, $font-numbers, $font-headline);
}

.fa {
  font-size: 2rem;
}

