 /*------------------------------------*\
  #CONTENT
\*------------------------------------*/

body, html {
  height: 100%;
}

body {
  overflow: hidden;
}

.l-wrap {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/*
  This ensures angular hidden directive works with
  elements that have a css display property
*/
[hidden] {
  display: none !important;
}
