/**
 * @doc ../../../styleguide/docs/css_markdown/iconography.md
 *
 * @section Iconography
 * @page Iconography
 */

// Sports Icons

.sports-icon {
  background: url('#{$img-path}/sports/icon-sprite-all.svg') -6px -122px no-repeat transparent;
  height: 60px;
  width: 60px;
  border-radius: 6px;
  display: inline-block;

  &--active {
    background-position: -6px -23px;
  }

  &--golf {
    background-position-x: -573px;
  }

  &--rugby-union {
    background-position-x: -1235px;
  }

  &--cricket {
    background-position-x: -285px;
  }

  &--tennis {
    background-position-x: -1458px;
  }

  &--volleyball {
    background-position-x: -1524px;
  }

  &--soccer {
    background-position-x: -439px;
  }

  &--darts {
    background-position-x: -366px;
  }

  &--basketball {
    background-position-x: -82px;
  }

  &--snooker {
    background-position-x: -1388px;
  }

  &--afl {
    background-position-x: -8px;
  }

  &--rugby-league {
    background-position-x: -1170px;
  }

  &--greyhounds {
    background-position-x: -643px;
  }

  &--handball {
    background-position-x: -720px;
  }

  &--gaa {
    background-position-x: -508px;
  }

  &--motor {
    background-position-x: -968px;
  }

  &--roulette {
    background-position-x: -1106px;
  }

  &--boxing {
    background-position-x: -216px;
  }

  &--ice-hockey {
    background-position-x: -886px;
  }

  &--horses {
    background-position-x: -802px;
  }

  &--blackjack {
    background-position-x: -1040px;
  }

  &--poker {
    background-position-x: -149px;
  }

  &--slots {
    background-position-x: -1312px;
  }
}

.icon {
  height: 30px;
  width: 30px;
  display: inline-block;
  background-repeat: no-repeat;
  vertical-align: middle;
  background-size: contain;
  background-position: center;

  &--cash {
    background-image: url('#{$img-path}/icons/cashout-inactive.svg');
  }

  &--cash-white {
    background-image: url('#{$img-path}/icons/cashout-active.svg');
  }

  &--cash-change {
    background-image: url('#{$img-path}/icons/cashout-value-changed.svg');
    height: 5rem;
    width: 100%;
  }

  &--inplay {
    background-image: url('#{$img-path}/icons/bet-leg-in-play.svg');
    height: 22px;
    width: 22px;
  }

  &--won {
    background-image: url('#{$img-path}/icons/bet-leg-won.svg');
    height: 22px;
    width: 22px;
  }

  &--lost {
    background-image: url('#{$img-path}/icons/bet-leg-lost.svg');
    height: 22px;
    width: 22px;
  }

  &--void {
    background-image: url('#{$img-path}/icons/bet-leg-void.svg');
    height: 22px;
    width: 22px;
  }

  &--stream {
    background-image: url('#{$img-path}/icons/bet-leg-streaming.svg');
  }

  &--add-bet {
    background-image: url('#{$img-path}/icons/header-add-bet.svg');

    &-light {
      background-image: url('#{$img-path}/icons/add-bet-light.svg');
      fill: #fff;
    }
  }

  &--sort {
    background-image: url('#{$img-path}/icons/header-sort.svg');
    height: 24px;
    width: 24px;
  }

  &--streaming {
    background-image: url('#{$img-path}/icons/bet-leg-streaming.svg');

    &-light {
      background-image: url('#{$img-path}/icons/bet-leg-streaming-light.svg');
    }
  }

  &--info {
    background-image: url('#{$img-path}/icons/info.svg');
    height: 16px;
    width: 16px;
  }
}
