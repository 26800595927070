/*------------------------------------*\
  #LOGO
\*------------------------------------*/

/**
 * @doc ../../../styleguide/docs/css_markdown/logo.md
 *
 * @section
 * @page Logo
 */

.logo {
  box-sizing: border-box;
  padding: 10rem 2.6rem 6rem;
  text-align: center;
  margin: 0 auto;
  display: block;
  background: color($accent-colors, pine-gradient);

  &--solid {
    background: none;
    background-color: color($accent-colors, pigment-green);
  }

  &--small {
    height: 10.7rem;
  }

  &__svg {
    background-image: url('#{$img-path}/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
  }
}
