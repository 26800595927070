/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/

/**
  * Button
  *
  * The Button `.button` class can be applied to `<button>`, `<input>`, or `<a>` elements.
  *
  * @section
  * @page Buttons
  *
  * @example
  * <button class="button">Add a bet</button>
  * <button class="button button--pressed">Add a bet</button>
  * <button class="button button--disabled">Add a bet</button>
  * </br></br>
  * <button class="button button--icon"><i class="fa fa-redo"></i><span>Add a bet</span></button>
  * <button class="button button--icon button--pressed"><i class="fa fa-redo"></i><span>Add a bet</span></button>
  * <button class="button button--icon button--disabled"><i class="fa fa-redo"></i><span>Add a bet</span></button>
**/

.button {
  $button: &;
  font-family: inherit;
  border-radius: 2px;
  background-color: color($accent-colors, pigment-green);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2.6rem;
  min-width: 14.6rem;
  width:100%;
  height: 4rem;
  outline: 0;
  color: #fff;
  border: none;

  font: {
    size: $font-button-size;
    family: $font-body;
  }

  text: {
    decoration: none;
  }

  &--pressed, :active {
    background: color($brand-colors, paddy-dark-green);
  }

  &--disabled, &:disabled {
    opacity: 0.6;
  }

  &:visited {
    color: #fff;
  }

  &--icon {
    & i {
      font-size: $font-button-size;
      margin-right: 1rem;
    }
  }

  &--center {
    margin: 0 auto;
  }

  /**
    * Primary Large Button
    *
    * @section
    * @page Buttons
    *
    * @example
    * <button class="button button--large">Add a bet</button>
    * <button class="button button--large button--pressed">Add a bet</button>
    * <button class="button button--large button--disabled">Add a bet</button>
    * </br></br>
    * <button class="button button--large button--icon"><i class="fa fa-redo"></i><span>Add a bet</span></button>
    * <button class="button button--large button--icon button--pressed"><i class="fa fa-redo"></i><span>Add a bet</span></button>
    * <button class="button button--large button--icon button--disabled"><i class="fa fa-redo"></i><span>Add a bet</span></button>
  **/
  &--large {
    font-size: $font-button-large-size;
    padding: 0 3.2rem;
    height: 5.6rem;
  }

    /**
    * Secondary Button
    *
    * @section
    * @page Buttons
    *
    * @example
    * <div style="padding:20px 25px">
    * <button class="button button--alpha">Add another</button>
    * <button class="button button--alpha button--pressed">Add another</button>
    * <button class="button button--alpha button--disabled">Add another</button>
    * </div>
    * </br></br>
    * <div class="paddy-dark-green" style="padding:20px 25px">
    * <button class="button button--alpha">Add another</button>
    * <button class="button button--alpha button--pressed">Add another</button>
    * <button class="button button--alpha button--disabled">Add another</button>
    * </div>
    * </br></br>
    * <div class="paddy-green" style="padding:20px 25px">
    * <button class="button button--alpha">Add another</button>
    * <button class="button button--alpha button--pressed">Add another</button>
    * <button class="button button--alpha button--disabled">Add another</button>
    * </div>
  **/
  &--alpha {
    background-color: color($accent-colors, panache);
    color: color($brand-colors, paddy-dark-green);

    &#{$button}--pressed, #{$button}:active {
      background-color: rgba(color($accent-colors, panache), 0.2);
    }

    &#{$button}--disabled {
      background-color: rgba(color($accent-colors, panache), 0.4);
    }
  }

  &--muted {
    background-color: transparent;
    color: color($greys, dark-grey);
  }

  &-group {
    display: flex;
    width: 100%;

    & > * {
      &:first-child {
        margin: 0 0.6rem 0 0;
      }

      &:last-child {
        margin: 0 0 0 0.6rem;
      }
    }

    @media all and (max-width:$media-xs-width)
    {
      flex-direction: column;
      width: 100%;
      align-items: center;

      & > * {
        margin-bottom: 1rem;
        width: 100%;

        &:first-child {
          margin: 0 0 1rem;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

/**
 * @doc ../../../styleguide/docs/css_markdown/buttons.md
 *
 * @section
 * @page Buttons
 */
