.spinner {
  border: .8rem solid rgba(0,0,0,.10);
  border-radius: 50%;
  border-top: .8rem solid #31953E;
  width: 5rem;
  height: 5rem;
  animation: spin 2s linear infinite;

  &--small {
    width: 2.5rem;
    height: 2.5rem;
  }

  &--light {
    border-width: .4rem;
    border-color: #C4C4C4;
    border-top-color: color($greys, ghost-white);
  }

  &--center {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
