@font-face {
  font-family: 'Penumbra Sans';
  src: url('/assets/fonts/penumbrasans/PenumbraSansStd-Bold.eot');
  src: url('/assets/fonts/penumbrasans/PenumbraSansStd-Bold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/penumbrasans/PenumbraSansStd-Bold.woff2') format('woff2'),
      url('/assets/fonts/penumbrasans/PenumbraSansStd-Bold.woff') format('woff'),
      url('/assets/fonts/penumbrasans/PenumbraSansStd-Bold.svg#PenumbraSansStd-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Penumbra Sans';
  src: url('/assets/fonts/penumbrasans/PenumbraSansStd-Regular.eot');
  src: url('/assets/fonts/penumbrasans/PenumbraSansStd-Regular.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/penumbrasans/PenumbraSansStd-Regular.woff2') format('woff2'),
      url('/assets/fonts/penumbrasans/PenumbraSansStd-Regular.woff') format('woff'),
      url('/assets/fonts/penumbrasans/PenumbraSansStd-Regular.svg#PenumbraSansStd-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Penumbra Sans';
  src: url('/assets/fonts/penumbrasans/PenumbraSansStd-Semibold.eot');
  src: url('/assets/fonts/penumbrasans/PenumbraSansStd-Semibold.eot?#iefix') format('embedded-opentype'),
      url('/assets/fonts/penumbrasans/PenumbraSansStd-Semibold.woff2') format('woff2'),
      url('/assets/fonts/penumbrasans/PenumbraSansStd-Semibold.woff') format('woff'),
      url('/assets/fonts/penumbrasans/PenumbraSansStd-Semibold.svg#PenumbraSansStd-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('/assets/fonts/MYRIADPRO-REGULAR.OTF');
  font-weight: 400;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('/assets/fonts/MYRIADPRO-BOLD.OTF');
  font-weight: 700;
}

/* Webfont: PaddyMischief-Bold */
@font-face {
  font-family: 'PaddyMischief';
  src: url('/assets/fonts/Paddy Mischief.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Paddy Mischief.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/Paddy Mischief.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/Paddy Mischief.ttf') format('truetype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}
