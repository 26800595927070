.terms {

  &__container {
    @include container();
    max-width: 100%;
  }

  &__success {
    padding: 1.5rem;
    box-shadow: 0px 0px 2px rgba(color($greys, charcoal), 0.25);
    border-radius: 2px;
    overflow: auto;
  }

  & h2 {
    display: none;
  }
  & a {
    cursor: pointer;
    color: color($brand-colors, paddy-green);
  }

  ul, ol {
    padding-left: 2.5rem;
    position: relative;
    margin-bottom: 2.5rem;

    li {
      list-style-type: initial;
      color: inherit;
      font-style: inherit;
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 150%;
      margin-bottom: .5rem;
    }
  }

  & ol {
    margin-top: 2.5rem;

    & > ul {
      padding-left: 2.5rem;

      li {
        list-style-type: circle;
      }
    }
  }

  & h3 {
    font-size: 1.5rem;
    line-height: 150%;
    font-weight: 700;
    margin-bottom: .5rem;
    color: color($greys, charcoal);
  }

  & h4 {
    margin-bottom: 1.5rem;
    font-weight: 600;
  }

  & p{
    font-size: 1.3rem;
    line-height: 150%;
    color: color($greys, ash);
    opacity: 0.9;
    font-weight: 400;
    margin-bottom: 2.5rem;

    li:last-of-type & {
      margin-bottom: 0;
    }
  }
}
