.footer__brands {
    max-width: $max-width;
    width: 100%;
    padding-top: 3.5rem;

    .row {
      align-items: center;

      & > * {
        border: 0;
      }
    }
}
