 /*------------------------------------*\
  #GRID SYSTEM
\*------------------------------------*/

/**
  * @doc ../../../styleguide/docs/css_markdown/gridsystem.md
  *
  * @section
  * @page GridSystem
**/

$grid-columns: 12;

.l-grid {
  display: grid;

  @for $i from 1 through $grid-columns {
    &-#{$i} {
      width: #{$i} / #{$grid-columns};
    }
  }
}



/*grid*/
.row {
	display:flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.row > *{ padding:.75em; border:1px solid #bbb;}

.col1,.col1-s{width:8.333333%}
.col2,.col2-s{width:16.666666%}
.col3,.col3-s{width:25%}
.col4,.col4-s{width:33.333333%}
.col5,.col5-s{width:41.666666%}
.col6,.col6-s{width:50%}
.col7,.col7-s{width:58.333333%}
.col8,.col8-s{width:66.666666%}
.col9,.col9-s{width:75%}
.col10,.col10-s{width:83.333333%}
.col11,.col11-s{width:91.666666%}
.col,.col-s{width:100%}

@media all and (max-width:767px)
{
	/*all .col classes that don't end with "-s" become 100% wide on mobile */
	.row > [class^="col"]:not([class*="s"]){width:100%;}
}
