/*------------------------------------*\
  #MIXINS
\*------------------------------------*/

// BEM selectors

@mixin font($weight, $line-height, $size: $font-body-size, $family: $font-body) {
  font: {
    size: $size;
    family: $family;
    weight: $weight;
  }

  line-height: $line-height;
}

// button Theme
@mixin button($map) {

  @each $theme,
  $color in $map {
    &--#{$theme} {
      background-color: $color;

      &:hover,
      &:focus,
      &:active {
        background-color: lighten($color, 3%);
        margin: 0;
        text-decoration: initial;
      }

      &:visited {
        color: color($greys, ghost-white);
      }
    }
  }
}

// Container

@mixin container($properties...) {
  $properties: (#fff, 2px, 0.10);
  background-color: nth($properties, 1);
  border-radius: nth($properties, 2);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, nth($properties, 3));
}


// Input styles

@mixin formInputs {
  background-color: color($greys, ghost-white);
  color: color($greys, charcoal);
  display: block;
  line-height: 2.75rem;
  border-radius: 0.2rem;
  outline: 0;
  margin-top: 0.75rem;
  border: none;
  padding: 1.5rem 1.2rem;

  font: {
    size: $font-input-size;
    family: $font-body;
  }

  &::placeholder {
    color: color($greys, manatee);
  }

  &:focus,
  &--focus {
    box-shadow: 0px 0px 2px color($greys, manatee);
    background: rgba(0, 158, 81, 0.05);
    border: 1px solid rgba(0, 104, 88, 0.2);
    box-sizing: border-box;
    padding: calc(1.5rem - 1px) calc(1.2rem - 1px);
  }

  &:disabled,
  &--disabled {
    opacity: 0.5;
  }

}

// flex
@mixin flex {
  display: flex;
  align-items: center;
}

@mixin flex-column {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

@mixin flex-column-fill {
  @include flex-column;
  flex: 1 1 auto;
}

@mixin flex-with-right-item {
  @include flex;

  & > *:last-child {
    margin-left: auto;
  }
}

@mixin overlay {
  background-color: color($accent-colors, dark-green);
  opacity: 0.8;
}

@mixin hello {
  color: color($brand-colors, paddy-dark-green);
  font-size: 4.8rem;
  margin-bottom: 5rem;

  & > span {
    color: color($accent-colors, pigment-green);
  }
}
